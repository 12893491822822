import React, { useEffect, useMemo, useState } from "react";
import { Container_center } from "customizados/estilizados";
import { useTranslation } from "react-i18next";
import Rodape from "components/rodape/index"
import Img from "img/inovacao/inovacao-escura.png"
import app_img from "img/inovacao/relatorios/app.png"
import img_bi from "img/inovacao/relatorios/bi.png"
import img_cambio from "img/inovacao/relatorios/cambio.png"
import img_follow_up from "img/inovacao/relatorios/follow-up.png"
import img_ouvidoria from "img/inovacao/relatorios/ouvidoria.png"
import img_rastreio from "img/inovacao/relatorios/rastreio.png"
import img_relatorio from "img/inovacao/relatorios/relatorio.png"
import Metodos from "util/helper";

import "./style.scss"
import Cabecalho_padrao from "components/cabecalho-padrao";

const Inovacao = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);


    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    const [img1, setImg1] = useState(false)
    const [img2, setImg2] = useState(false)
    const [img3, setImg3] = useState(false)
    const [img4, setImg4] = useState(false)
    const [img5, setImg5] = useState(false)
    const [img6, setImg6] = useState(false)
    const [img7, setImg7] = useState(false)

    return (
        <div className="inovacao">
            <Cabecalho_padrao imagem={Img} imagemEscura/>
            <Container_center>
                {img1 &&
                    <div className="modal" onClick={() => { setImg1(!img1) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-primeira-tela" style={{ background: `url(${app_img}) center no-repeat `, backgroundSize: "90%" }}>
                            </div>
                        </div>
                    </div>
                }
                {img2 &&
                    <div className="modal" onClick={() => { setImg2(!img2) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-segunda-tela" style={{ background: `url(${img_bi}) center no-repeat `, backgroundSize: "95%" }}>
                            </div>
                        </div>
                    </div>
                }
                {img3 &&
                    <div className="modal" onClick={() => { setImg3(!img3) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-terceira-tela" style={{ background: `url(${img_follow_up}) center no-repeat `, backgroundSize: "cover" }}>
                            </div>
                        </div>
                    </div>
                }
                {img4 &&
                    <div className="modal" onClick={() => { setImg4(!img4) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-quarta-tela" style={{ background: `url(${img_cambio}) center no-repeat `, backgroundSize: "95%" }}>
                            </div>
                        </div>
                    </div>
                }
                {img5 &&
                    <div className="modal" onClick={() => { setImg5(!img5) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-quinta-tela" style={{ background: `url(${img_ouvidoria}) center no-repeat `, backgroundSize: "cover" }}>
                            </div>
                        </div>
                    </div>
                }
                {img6 &&
                    <div className="modal" onClick={() => { setImg6(!img6) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-sexta-tela" style={{ background: `url(${img_rastreio}) center no-repeat `, backgroundSize: "cover" }}>
                            </div>
                        </div>
                    </div>
                }
                {img7 &&
                    <div className="modal" onClick={() => { setImg7(!img7) }}>
                        <div className="container">
                            <span class="close">
                            <i class="fa-solid fa-x fa-2x"></i>
                            </span>
                            <div className="img" id="tamanho-setima-tela" style={{ background: `url(${img_relatorio}) center no-repeat `, backgroundSize: "cover" }}>
                            </div>
                        </div>
                    </div>
                }
                <div className="inovacao-content">
                    <div className="inovacao-info">
                        <h1>{t("inovacao.titulo")}</h1>
                        <p>{t("inovacao.primeiroParagrafoInovacao")}</p>
                        <p>{t("inovacao.segundoParagrafoInovacao")}</p>
                    </div>
                    <div className="combo-cards-inovacao">
                        <div className="bloco bloco1">
                            <div className="card card1" onClick={() => { setImg1(!img1) }} >
                                <h1>{t("inovacao.tituloPrimeiroCardInovacao")}</h1>
                            </div>
                            <div className="card card1" onClick={() => { setImg2(!img2) }}>
                                <h1>{t("inovacao.tituloSegundoCardInovacao")}</h1>
                            </div>
                            <div className="card extra">
                            </div>
                        </div>
                        <div className="bloco bloco2">
                            <div className="card card3" onClick={() => { setImg3(!img3) }}>
                                <h1>{t("inovacao.tituloTerceiroCardInovacao")}</h1>
                            </div>
                            <div className="card card4" onClick={() => { setImg4(!img4) }}>
                                <h1>{t("inovacao.tituloQuartoCardInovacao")}</h1>
                            </div>
                            <div className="card card5" onClick={() => { setImg5(!img5) }}>
                                <h1>{t("inovacao.tituloQuintoCardInovacao")}</h1>
                            </div>
                        </div>
                        <div className="bloco  bloco3">
                            <div className="card extra">
                            </div>
                            <div className="card card6" onClick={() => { setImg6(!img6) }}>
                                <h1>{t("inovacao.tituloSextoCardInovacao")}</h1>
                            </div>
                            <div className="card card7" onClick={() => { setImg7(!img7) }}>
                                <h1>{t("inovacao.tituloSetimoCardInovacao")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Container_center>
            <Rodape />
        </div>
    )
}

export default Inovacao;