import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import img2010 from 'img/sobrenos/2010.png';
import img2011 from 'img/sobrenos/2011.png';
import img2012 from 'img/sobrenos/2012.png';
import img2013 from 'img/sobrenos/2013.png';
import img2014 from 'img/sobrenos/2014.png';
import img2015 from 'img/sobrenos/2015.png';
import img2016 from 'img/sobrenos/2016.png';
import img2017 from 'img/sobrenos/2017.png';
import img2018 from 'img/sobrenos/2018.png';
import img2019 from 'img/sobrenos/2019.png';
import img2020 from 'img/sobrenos/2020.png';
import img2021 from 'img/sobrenos/2021.png';
import img2022 from 'img/sobrenos/2022.jpg';
import img2023 from 'img/sobrenos/2023.jpg';
import ImgComp from './imagens';

const Carosel = () => {
    const { t } = useTranslation(['home']);

    let sliderArr = [
        <ImgComp src={img2010} ano={2010} texto_array={[t('carousel.2010.primeiroTexto'), t('carousel.2010.segundoTexto')]} />,
        <ImgComp src={img2011} ano={2011} texto_array={[t('carousel.2011.primeiroTexto')]} />,
        <ImgComp src={img2012} ano={2012} texto_array={[t('carousel.2012.primeiroTexto')]} />,
        <ImgComp src={img2013} ano={2013} texto_array={[t('carousel.2013.primeiroTexto')]} />,
        <ImgComp src={img2014} ano={2014} texto_array={[t('carousel.2014.primeiroTexto'), t('carousel.2014.segundoTexto')]} />,
        <ImgComp src={img2015} ano={2015} texto_array={[t('carousel.2015.primeiroTexto'), t('carousel.2015.segundoTexto'), t('carousel.2015.terceiroTexto')]} />,
        <ImgComp src={img2016} ano={2016} texto_array={[t('carousel.2016.primeiroTexto'), t('carousel.2016.segundoTexto'), t('carousel.2016.terceiroTexto')]} />,
        <ImgComp src={img2017} ano={2017} texto_array={[t('carousel.2017.primeiroTexto'), t('carousel.2017.segundoTexto'), t('carousel.2017.terceiroTexto')]} />,
        <ImgComp src={img2018} ano={2018} texto_array={[t('carousel.2018.primeiroTexto'), t('carousel.2018.segundoTexto')]} />,
        <ImgComp src={img2019} ano={2019} texto_array={[t('carousel.2019.primeiroTexto'), t('carousel.2019.segundoTexto'), t('carousel.2019.terceiroTexto')]} />,
        <ImgComp src={img2020} ano={2020} texto_array={[t('carousel.2020.primeiroTexto'), t('carousel.2020.segundoTexto'), t('carousel.2020.terceiroTexto'), t('carousel.2020.quartoTexto')]} />,
        <ImgComp src={img2021} ano={2021} texto_array={[t('carousel.2021.primeiroTexto'), t('carousel.2021.segundoTexto'), t('carousel.2021.terceiroTexto')]} />,
        <ImgComp src={img2022} ano={2022} texto_array={[t('carousel.2022.primeiroTexto'), t('carousel.2022.segundoTexto'), t('carousel.2022.terceiroTexto'), t('carousel.2022.quartoTexto')]} />,
        <ImgComp src={img2023} ano={2023} texto_array={[t('carousel.2023.primeiroTexto'), t('carousel.2023.segundoTexto'), t('carousel.2023.terceiroTexto')]} />
    ];

    const [x, setX] = useState(0);

    const Left = () => {
        x === 0 ? setX(-100 * (sliderArr.length - 1)) : setX(x + 100);
    };

    const Rigth = () => {
        x === -100 * (sliderArr.length - 1) ? setX(0) : setX(x - 100);
    };

    return (
        <>
            <div className='slider'>
                {sliderArr?.map((item, index) => {
                    return (
                        <div className='slide' key={index} style={{ transform: `translateX(${x}%)` }}>
                            {item}
                        </div>
                    );
                })}
                <span className='arrow' id='rigth' onClick={Rigth}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </span>
                <span className='arrow' id='left' onClick={Left}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </span>
            </div>
            <div className='timeline'>
                <div className='anos'>
                    {sliderArr?.map((item, index) => {
                        return (
                            <div className='data' onClick={() => setX(-(index * 100))}>
                                <p>{item?.props?.ano}</p>
                                <div>
                                    <FontAwesomeIcon icon={faCircle} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Carosel;
