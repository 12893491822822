import React, { useEffect, useMemo } from "react";
import { Styled_h1 } from "customizados/estilizados";
import { Container_center } from "customizados/estilizados"
import Rodape from "components/rodape/index"
import Img from "img/onde-estamos/world-escura.png"
import mapa from "img/mapa.png"
import poligono from "img/poligono.png"
import { useTranslation } from "react-i18next";
import Metodos from "util/helper";
import Cabecalho_padrao from "components/cabecalho-padrao";

import "./style.scss"

const OndeEstamos = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);

    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    return (
        <>
            <Container_center>
                <Cabecalho_padrao imagem={Img} />
                <div className="mapa-quattror">
                    <img src={poligono} alt="" />
                    <Styled_h1>{t("ondeEstamos.OndeEstamos")}</Styled_h1>
                    <p>{t("ondeEstamos.textoOndeEstamos")}</p>
                    <div className="mapa-content">
                        <svg className="mapa" width="744.09448" height="700" xmlns="http://www.w3.org/2000/svg">
                            <path d="m362.269348,688.907166l-8.709106,1.194519l1.589111,-6.72229l3.80777,-2.433594l-1.905518,-13.225342l-4.127411,-0.846619l-4.444702,-8.146851l-0.635925,-7.512085l-6.666626,-2.750977l-10.475403,-0.211792l-10.15802,-5.925049l-2.2229,-5.607666l-9.205841,-5.607666l-14.601654,-2.116211l16.503876,-18.303772l1.268524,-8.146851l9.203552,-12.590515l9.363281,-5.581055l0.158539,-7.168091l16.663574,-3.041809l32.533691,0.76709l37.294739,21.080811l2.380371,17.589417l-7.141663,16.637207l-5.991058,4.635437l-5.356201,9.396423l-3.769226,7.809448l-18.399475,10.839661l-3.481506,6.713379l-2.529358,10.839661l-6.424927,2.471863l-4.520508,5.963318z" 
                                id="path1360" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m425.531525,610.897522l-5.286774,1.909363l-2.420227,-17.119934l-38.604187,-21.563599l-33.208282,0.337219l-5.276825,0.654663l10.910706,-6.328247l3.293091,-5.69342l-0.198364,-6.64563l24.559082,-0.297546l9.006317,4.780884l8.688904,1.289429l6.467102,-3.154175l4.880096,-5.058655l18.528412,-0.614929l15.036987,-2.836792l-4.959412,8.272339l-0.843109,38.093323l-7.508606,6.987854l-3.064911,6.987854z" 
                                id="santacatarina" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#6f9999" />
                            <path d="m442.869263,549.485229l3.255554,-1.964539l4.527832,-2.825256l-5.650452,-4.022644l-9.841492,-1.32843l-16.42749,-34.856873l-33.790405,-15.398438l-12.835144,-0.729675l-27.503815,24.416687l0.336823,31.900696l0.636108,3.461365l9.617004,-0.430359l-0.261963,8.550537l24.585083,-0.130981l7.222046,4.06012l10.515076,1.964539l7.652496,-4.106873l4.060089,-4.705566l17.699707,-0.130981l16.202942,-3.723328z" 
                                id="path2880" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m445.919037,540.887939l5.03299,3.208801l10.047302,-9.476685l2.263947,-8.279175l9.747955,-3.788818l14.837128,-2.291992l3.760712,-1.693237l1.365845,-4.68689l7.353027,0.102905l7.652466,-10.674133l0.76709,-4.387512l-8.213745,-5.584961l-11.207306,4.8927l-7.914398,3.695251l-6.716888,-0.795166l-0.729706,-23.546692l-10.608612,-24.444702l-27.372894,-0.196472l-16.296448,-10.97345l-16.595886,-1.393921l0.467773,8.485046l-4.022705,0.402222l-9.71051,11.478638l-1.32843,16.56781l-15.398407,10.580566l12.44223,0.701599l33.397491,15.669739l16.333954,35.427551l10.646027,1.000977z" 
                                id="sao-paulo" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#6f9999" data-bs-toggle="tooltip" />
                            <path d="m500.945374,513.861206l6.78595,-10.841309l0.670044,-3.98645l-7.712097,-6.081909l7.854797,-0.693481l14.73999,-0.992798l9.950256,-2.78894l7.256042,-3.98645l6.058533,-7.279358l6.058594,-8.776245l15.338745,15.172729l0.542603,9.560852l-3.105896,4.153625l-13.284119,3.255554l-2.207825,5.949829l-2.507141,2.058105l-36.035645,0.261963l-1.60907,4.453003l-8.793762,0.561279z" 
                                id="path4400" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m567.891724,483.107849l1.462158,-2.112l3.12738,-1.218445l4.751953,0.406189l1.827698,-4.467712l0.69043,-6.417175l3.411682,-4.386414l6.173523,-7.473206l2.274414,-11.209778l-0.32489,-8.610413l2.274414,-7.63562l-1.299683,-5.686096l-6.498413,-1.462158l-8.610413,-2.924316l-6.660889,0l-0.649841,19.82019l-3.086731,8.123047l-4.548889,9.585144l-9.74762,1.137207l0.32489,9.585205l15.108826,14.94635z" 
                                id="espirito-santo" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#6f9999" data-bs-toggle="tooltip" />
                            <path d="m597.617554,423.870605l-5.388794,-0.414551l-11.425232,-10.803467l-2.720337,-8.316345l13.860596,-12.876099l-2.305786,-5.829163l-17.643066,-14.94873l-11.425293,0.388611l-14.741394,-9.559937l-20.95929,-0.854919l-1.891235,-9.559906l-20.95929,14.896881l-13.912384,0.803101l1.010406,-69.665588l-10.596252,-0.025909l15.104126,-18.264893l9.300842,-0.025879l7.817627,13.050964l15.279053,-8.504181l7.817627,-8.089661l-8.194092,-10.493408l41.133972,-0.130341l9.21582,-8.420776l4.241577,0.698669l8.801331,11.890808l19.578857,-15.882172l18.335327,11.890778l0.478394,15.580109l9.18335,0.657288l-0.350647,6.460602l-9.884705,1.071838l0.063904,11.020386l7.939758,7.289612l7.110718,2.31543l-1.494629,5.626373l-5.225403,3.139252l-0.665588,7.284515l-3.567322,3.139252l-3.98175,4.797363l-7.297974,3.968292l-6.468933,0.65213l2.352112,6.713867l-0.5495,38.63205l-4.694763,4.641235l-1.793152,5.470337l-1.37854,10.030029l-2.622192,5.884827l-0.5495,13.346252l-5.938354,1.325073z" 
                                id="path5920" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m477.424103,358.451416l10.072632,-0.114288l0.96402,8.028229l13.941956,-0.777649l20.940674,-14.941742l1.88855,9.609406l20.940674,0.83313l14.775146,9.553894l11.442383,-0.388855l17.607971,14.941772l2.332886,5.832275l-13.886353,12.886597l2.721741,8.331848l11.442383,11.192444l-6.49884,-1.027588l-8.609558,-2.94397l-6.665466,0l-0.611023,19.829773l-3.110535,8.10968l-4.554749,9.609375l-9.720459,1.110962l0.249939,9.74823l-6.193359,8.915039l-6.054443,7.276489l-7.220947,3.999268l-9.942627,2.777283l-14.775146,0.999817l-7.83197,0.666565l4.610291,3.666016l-4.776917,-3.277222l-11.220215,4.888062l-7.887451,3.721558l-6.720978,-0.777649l-0.722137,-23.551331l-10.609192,-24.440063l-27.383972,-0.222168l-16.274841,-10.998047l-16.608154,-1.388672l20.463623,-18.624329l35.393372,-0.902771l1.257813,-16.916443l9.645203,-15.73645l0.67392,-18.658508l6.485992,0.069153l0.028168,-10.909119z" 
                                id="path6682" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m649.915161,301.726166l-8.868591,7.671143l-6.174316,-2.507141l-8.269836,-6.698242l-1.085205,-11.487976l10.889282,-1.010345l9.392456,0.48645l12.685425,1.983276l-8.569214,11.562836z" 
                                id="path8207" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m646.384888,288.267273l12.309082,1.584229l7.434998,-1.513306l0.855347,-7.303436l7.434998,-2.039673l5.329529,-10.988007l0.855347,-4.934723l-13.883057,4.276733l-13.093506,1.381744l-5.518677,-2.09726l-4.202759,-3.676361l-3.149963,-3.150024l-3.676392,0.271423l-5.255493,3.166443l-4.465942,2.113708l0.00824,15.009827l9.409912,0.527405l-0.327942,6.843872l9.936279,0.527405z" 
                                id="path9725" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m680.538391,263.006531l4.737366,-2.89502l3.653748,-9.526093l0.232361,-16.105743l-8.452759,7.054596l-14.506104,0.738159l-11.347839,8.370544l-7.926392,-19.263977l-12.927002,10.21283l-8.452759,-8.736511l-8.668701,10.379364l-11.563721,-12.254578l-15.511536,0.378326l-0.246765,12.484894l-10.247803,8.800293l1.069214,4.326111l8.701599,11.695313l19.755371,-15.939209l18.17627,12.748077l10.543884,-5.411774l4.490662,0.904724l6.596069,5.642059l6.332947,1.957428l12.649353,-1.727142l12.912537,-3.832672z" 
                                id="pernambuco" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#6f9999" data-bs-toggle="tooltip" />
                            <path d="m660.075745,223.374084l0.140869,-9.162903l29.143127,5.934662l-0.185486,14.815796l-8.45282,7.054626l-14.506042,0.738159l-11.347839,8.370544l-7.926392,-19.263977l-12.927002,10.21283l-8.452759,-8.736511l-7.213684,-15.832642l5.326355,-7.034576l10.356812,6.477753l15.943665,-4.728302l0.949341,10.808594l9.151855,0.345947z" 
                                id="path12003" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m689.193848,219.975525l0.569946,-8.766846l-3.671082,-5.162048l-2.252991,-13.928864l-28.122864,-0.357849l-13.491577,-9.475891l-18.766174,28.255371l11.132507,6.414459l15.161377,-4.399994l1.16626,10.443329l9.22406,0.477112l0.318115,-9.489136l28.732422,5.990356z" 
                                id="path12007" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m590.167603,231.426117l-12.351807,-79.703461c28.750122,-2.182312 49.230469,13.87146 64.621582,30.349335l-24.014404,35.438446l7.156616,15.930115l-8.11084,9.356598l-12.351807,-11.848175l-14.949341,0.477142z" 
                                id="path12009" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m503.123352,278.820129l-9.225433,0.426788l0.318085,-23.263031c6.114014,-21.71283 21.34613,-34.943695 41.879395,-42.237244l10.708435,-0.251831l0.742188,-31.634857l19.932434,-32.814392l10.390381,2.597565l12.298767,78.934784l-0.212036,13.199982l-10.814453,8.110809l1.272278,5.354218l-4.240906,-1.312042l-9.330139,8.654205l-40.501099,0.596375l7.898743,10.221375l-8.216858,7.888855l-15.638489,8.73703l-7.261292,-13.208588z" 
                                id="path12011" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m466.378448,193.590485l0.557129,37.372559l8.962616,7.154785l9.279114,0.296509l-0.198029,9.021851l-9.116516,0.391846l0.85495,15.934143l8.91272,15.560455l7.887329,-0.511932l0.318054,-22.304779c6.114014,-21.712799 21.34613,-34.943695 41.879395,-42.237244l10.708435,-0.251801l0.742188,-31.634888l19.932495,-32.814392l-29.493835,-9.097778l-3.295044,4.564362l-5.909729,-1.494202l-9.315063,-11.981171l-5.979431,-1.510315l-9.877869,-6.308411l-4.779907,-2.709839l-8.46701,-1.052307l-13.265228,43.030151l-23.760986,21.738617l13.424225,8.843781z" 
                                id="path12013" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m456.1138,193.822937l-13.161652,0l10.57132,-9.381195l12.531586,9.101166l1.050171,37.104736l9.451202,7.700989l8.611115,-0.140015l-0.070068,9.381195l-9.311157,-0.140045l0.726318,15.314484l8.567383,16.434601l8.965515,-0.647583l-15.353821,18.394836l10.247528,-0.087524l-0.113708,22.315369l-20.556458,11.113922l-18.0065,0.164063l-7.645142,-6.836823l-5.404938,-0.115936l-5.684937,8.005096l-11.285645,-5.436646l-0.644318,-16.638031l-8.765381,8.845154l-3.311462,-0.449036l0.04895,-16.411072l7.329926,-30.412872l19.964783,-27.345734l11.003632,-29.866058l6.523071,-13.063904l3.722687,-6.903137z" 
                                id="path12773" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m470.49707,381.80304l0.334473,-12.617615l-20.948303,-3.49646l-0.516907,16.691772l21.130737,-0.577698z" 
                                id="path12777" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m408.339844,327.331879l-0.687225,-9.771576l3.332733,0.444366l8.776245,-8.831726l0.610992,16.608124l11.331268,5.443451l5.665649,-7.998566l5.38797,0.111115l7.665253,6.832092l17.996765,-0.166626l20.496338,-11.109131l-0.610992,44.436493l-0.666504,-5.276855l-10.053802,0.111115l-0.055542,10.942474l-6.443268,-0.111084l-0.333282,9.220581l0.222198,-9.331665l-20.940704,-3.49939l-0.499908,16.719238l21.051788,-0.555481l-0.222198,6.110046l-9.609406,15.774902l-1.277527,16.885864l-35.382538,0.888733l-20.440765,18.66333l-34.554718,-26.71936l-0.173065,-29.182068l36.725586,-49.08725l2.68866,-7.551147z" 
                                id="path12785" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m403.828308,439.927063l-0.111084,9.165039l-3.999298,0.388794l-9.720428,11.497986l-1.333099,16.552551l-15.386169,10.609192l-28.494873,24.662292l-14.759583,4.625244l0.770416,-21.236145l-6.605988,-0.09021l-1.196625,-5.253723l-6.851929,-0.09021l0.262512,-3.381897l-27.768127,0.060486l-2.68811,-5.348938l2.721375,-11.741943l-0.549438,-45.090332l4.974731,-12.595764l7.187653,-10.136841l4.237091,-4.481628l18.498291,0.436096l12.057617,8.600769l13.286987,-0.005066l5.910583,-5.660461l2.768463,-1.968323l0.063843,13.522278l12.175842,0.243347l34.549347,26.717407z" 
                                id="path12794" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m239.057068,344.361511l-2.134567,-6.803345l17.050034,-31.733459l-8.578705,-0.559998l-6.23056,-8.64209l8.264221,-0.260742l-0.154907,-6.736938l-26.707336,0.06311l-0.478668,-37.17514l51.654846,0.386902l9.559479,-28.108459l12.797577,29.853699l120.765259,16.298492l-7.332001,30.438965l-0.055542,16.385956l0.111084,0l0.666504,9.553864l-2.666168,7.498657l-36.715637,49.102295l0.166656,29.21698l0.055542,0.055542l-12.053375,-0.27771l-0.055573,-13.497559l-2.777283,1.944031l-5.887817,5.665649l-13.330933,0l-12.053406,-8.609558l-18.496704,-0.444336l-4.221436,4.499207l-7.165405,10.164856l-2.071655,5.11438l-3.672485,-3.305786l-3.789001,-14.552612l-6.995667,-5.72937l-2.138489,-4.110413l-0.843262,-7.996094l-28.043396,0.099182l-2.176941,-12.898254l-5.308182,-8.693848l4.019058,-3.395264l3.454163,-0.596985l-0.676056,-10.22818l-5.753265,-1.985626z" 
                                id="path12803" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m318.09845,55.419113l13.759216,0.705368l7.633423,-2.191452l0.53299,15.350861l12.227875,0.732254l35.199951,48.347168l8.886475,0.314606l0.532959,-13.051025l22.252075,-20.986847l2.621368,12.844833l9.075562,0.082291l2.285736,3.549759l20.208618,1.332382l-7.453918,9.984528l-0.458069,9.777466l4.869263,3.456146l5.27829,-3.588104l3.478485,-11.288757l24.042633,-0.215698l7.011017,9.804169l-13.27536,42.99231l-23.773529,21.773865l0.111084,0.111084l-10.109314,8.942841l13.164337,0l-3.721588,6.943207l-6.498779,13.053223l-10.998047,29.827972l-20.496307,27.189575l-120.311798,-16.191528l-12.775452,-29.828003l-7.883179,-10.320007l41.901794,-81.835419l-37.589111,-14.810577l-11.764221,-25.951385l5.262573,-18.890228l14.869141,-0.930222l5.262573,-4.68927l26.146362,-1.347885l0.494873,-10.999496z" 
                                id="path13568" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m339.557922,54.293869l0.53299,15.350876l12.227814,0.732254l35.200012,48.347183l8.886475,0.314575l0.53299,-13.050995l22.252045,-20.986862l4.683838,-8.212143l1.202881,-6.459991l-6.177307,-9.394089l-10.894531,-0.903061l-10.265564,-37.697449l-3.902924,-0.461624l-18.36911,36.332764l-35.909607,-3.911438z" 
                                id="path13575" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m244.525604,3.138344l-6.534866,-0.032167l-10.223083,15.283777l-9.701828,1.366562l-3.586258,4.472183l-11.939743,0.668976l-2.544266,6.290573l-4.207291,-2.85611l-10.094559,0.01355l-5.52121,-5.391312l-10.094559,-3.3125l-9.90332,-2.802979l12.963333,12.580048l2.153625,24.637024l12.49144,-0.181061l8.749619,7.718353l0.434479,8.549881l7.382034,8.864197l-0.517365,4.706589l-7.585236,10.942978l9.460815,10.111465l0.314178,9.279938l9.045059,6.7854l4.887482,-1.113983l5.483383,-14.414276l11.30397,-0.694244l9.225235,-13.166977l19.985168,1.022964l5.515381,-19.021835l-13.764343,-1.055161l-1.604004,-6.068024l-9.647278,-7.970932l3.870178,-42.686539l-3.902954,-1.293137l0.340027,-8.153328l-2.233246,-3.07991l0,0.000015z" 
                                id="path14335" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m207.998093,329.468903l29.168488,8.522125l17.050034,-32.372284l-8.578705,-0.560028l-6.23056,-8.64209l8.264221,-0.260712l-0.154907,-6.736938l-26.707336,0.701935l-0.478668,-37.17514l-7.189835,-0.782654l-12.710632,-15.051483l-11.406342,-0.052094l-47.274429,31.250977l7.179855,0.273956l5.568176,2.074127l2.633545,3.704498l-2.583679,9.247742l0.561188,10.870605l7.2798,10.625885l7.36702,6.902679l15.953735,8.684113l22.289032,8.77478z" 
                                id="rondonia" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#6f9999" data-bs-toggle="tooltip" />
                            <path d="m187.072357,62.113907l0.69986,-8.355408l8.776199,7.665283l0.444366,8.554016l7.332016,8.887299l-0.499908,4.721375l-7.554199,10.942474l9.442749,10.109299l0.333267,9.276123l8.998398,6.77655l4.888,-1.110901l5.499023,-14.441864l11.331284,-0.666534l9.220596,-13.164307l19.996429,0.999817l1.444183,-4.9991l-1.333099,4.832458l11.720093,25.939804l37.604401,14.830658l-41.881378,81.818665l7.720795,10.109314l-9.49826,27.939423l-51.657425,-0.388824l0,0.388824l-6.943192,-0.777649l-12.71994,-15.052856l-11.386856,-0.055542l-47.324844,31.272186l-9.034134,4.953491l-122.837521,-50.410309l1.95076,-3.39502l1.071951,-21.849609l5.542418,-0.205597l1.148491,-12.069275l8.618179,-0.205597l4.790913,-7.521118l10.063667,0.827393l7.678822,-9.459839l17.34549,0.64624l9.875801,-29.232635l-3.023491,-17.963501l-0.387138,-18.402863l-7.417435,-8.736206l0.491653,-10.49379l14.457626,0.388l0.199921,-6.095123l-9.027344,-0.382996l2.396919,-14.443634l25.499367,-0.680832l0.453857,-3.3172l3.09021,-0.241455l2.650841,4.152512l2.705986,-0.600006l8.361671,-6.979431l0.786682,13.310501l6.357941,8.875046l29.457031,-0.878799l19.775986,-8.064056l1.207092,-7.580795l11.09523,0.00592z" 
                                id="path14339" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                            <path d="m7.67683,221.305908l2.86598,1.042175l119.589437,49.959198l-5.47142,4.624664l-1.302719,5.927368l-6.839264,0.512939l-0.065155,5.202728l-5.015442,1.815643l-1.367859,-2.874115l-1.628395,2.59729l0.195396,6.765991l-46.18132,0.252411l-0.341949,-26.38208l-8.679359,2.538269l-4.250092,5.404236l-15.00161,0.374023l-0.671719,-8.484436l-9.53019,-0.147095l-13.846472,-15.278534l-0.03764,-7.983337l-6.81179,-4.856812l0.371981,-21.187561l4.019609,0.177032l-0.00001,0z" 
                                id="path14348" stroke-miterlimit="4" stroke-linejoin="round" stroke-width="1.5" stroke="#fcf9f9" fill-rule="evenodd" fill="#CCC" />
                        </svg>
                        <div className="bloco1"></div>
                        <div className="bloco2"></div>
                        <div className="bloco3"></div>
                        <div className="bloco4"></div>
                        <div className="bloco5"></div>
                    </div>
                    <div className="img-mapa">
                        <img className="mapa-mobile" src={mapa} alt="mapa-quattror"></img>
                    </div>
                </div>
            {/* <div className="mapa-contato">
                <Mapa/>
            </div> */}
            </Container_center>
            <Rodape />
        </>
    )
}

export default OndeEstamos;