import React from "react";
import { Container } from "./customizados/estilizados"
import Rotas from "./rotas/rotas";
import "./i18n"

function App() {
  
  return (
      <Container >
          <Rotas />
      </Container>
  );
}
export default App;
