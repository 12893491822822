import React, { useEffect, useMemo } from "react";
import { Container_center } from "customizados/estilizados"
import { Styled_h1 } from "customizados/estilizados";
import Rodape from "components/rodape/index"
import img from "img/servicos/topo-escura.png"
import importacao from "img/servicos/importacao.jpg"
import exportacao from "img/servicos/exportacao.jpg"
import logistica from "img/servicos/logistica.jpg"
import acessoria from "img/servicos/acessoria.jpg"
import poligono from "img/poligono.png"
import ciclo from "img/circle-solid.svg"
import { useTranslation } from "react-i18next";
import Metodos from "util/helper";
import Cabecalho_padrao from "components/cabecalho-padrao";
import Img from "../../img/servicos/topo.jpg"

import "./style.scss"

const Servicos = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);

    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    return (
        <>
            <Container_center>
                <Cabecalho_padrao imagem={Img} imagemEscura />
                <div className="servicos">
                    <h1 className="servicosTitulo">{t("servicos.tituloServicos")}</h1>
                    <img className="poligono" src={poligono} alt="" />
                    <div className="tituloMarcaDaguaTop">{t("servicos.tituloImportacao")}</div>
                    <div className="lista-servicos">
                        <div className="imagem">
                            <div className="bloco">
                                <img src={importacao} alt="" />
                            </div>
                        </div>
                        <div className="imagem-lista-combo">
                            <Styled_h1 className="importacao">{t("servicos.tituloImportacao")}</Styled_h1>
                            <ul className="primeira-lista">
                                <li><img src={ciclo} />{t("servicos.itemPrimeiraFraseImportacao")}</li>
                                <li><img src={ciclo} /> {t("servicos.itemSegundaFraseImportacao")}</li>
                                <li><img src={ciclo} /> {t("servicos.itemTerceiraFraseImportacao")}</li>
                            </ul>
                        </div>
                    </div>
                    <ul className="segunda-lista">
                        <li><img src={ciclo} />{t("servicos.itemQuartaFraseImportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemQuintaFraseImportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSextaFraseImportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSetimaFraseImportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemOitavaFraseImportacao")}</li>
                    </ul>
                </div>
                <div className="tituloMarcaDagua">{t("servicos.tituloExportacao")}</div>
                <div className="servicos">
                    <div className="lista-servicos">
                        <div className="imagem">
                            <div className="bloco">
                                <img src={exportacao} alt="" />
                            </div>
                        </div>
                    </div>
                    <ul className="segunda-lista">
                        <Styled_h1 className="exportacao">{t("servicos.tituloExportacao")}</Styled_h1>
                        <li><img src={ciclo} />{t("servicos.itemPrimeiraFraseExportacao")} </li>
                        <li><img src={ciclo} /> {t("servicos.itemSegundaFraseExportacao")}</li>
                        <li><img src={ciclo} /> {t("servicos.itemTerceiraFraseExportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemQuartaFraseExportacao")}</li>
                        <li><img src={ciclo} /> {t("servicos.itemQuintaFraseExportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSextaFraseExportacao")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSetimaFraseExportacao")}</li>
                    </ul>
                </div>
                <div className="tituloMarcaDagua">{t("servicos.tituloLogisticaDistribuicao")}</div>
                <div className="servicos">
                    <div className="lista-servicos">
                        <div className="imagem">
                            <div className="bloco">
                                <img src={logistica} alt="" />
                            </div>
                        </div>
                        <div className="imagem-lista-combo">
                            <Styled_h1 className="logistica">{t("servicos.tituloLogisticaDistribuicao")}</Styled_h1>
                            <ul className="primeira-lista">
                                <li><img src={ciclo} />{t("servicos.itemPrimeiraFraseLogistica")}</li>
                                <li><img src={ciclo} />{t("servicos.itemSegundaFraseLogistica")}</li>
                            </ul>
                        </div>
                    </div>
                    <ul className="segunda-lista">
                        <li><img src={ciclo} />{t("servicos.itemTerceiraFraseLogistica")}</li>
                        <li><img src={ciclo} />{t("servicos.itemQuartaFraseLogistica")}</li>
                        <li><img src={ciclo} />{t("servicos.itemQuintaFraseLogistica")}</li>
                        <li><img src={ciclo} /> {t("servicos.itemSextaFraseLogistica")}</li>
                    </ul>
                </div>
                <div className="tituloMarcaDagua">{t("servicos.tituloAcessoriaConsultoria")}</div>
                <div className="servicos">
                    <div className="lista-servicos lista-2">
                        <div className="imagem">
                            <div className="bloco">
                                <img src={acessoria} alt=""/>
                            </div>
                        </div>
                    </div>
                    <ul className="segunda-lista">
                        <Styled_h1 className="assessoria">{t("servicos.tituloAcessoriaConsultoria")}</Styled_h1>
                        <li><img src={ciclo} />{t("servicos.itemPrimeiraFraseAcessoria")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSegundaFraseAcessoria")}</li>
                        <li><img src={ciclo} />{t("servicos.itemTerceiraFraseAcessoria")}</li>
                        <li><img src={ciclo} />{t("servicos.itemQuartaFraseAcessoria")}</li>
                        <li><img src={ciclo} /> {t("servicos.itemQuintaFraseAcessoria")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSextaFraseAcessoria")}</li>
                        <li><img src={ciclo} />{t("servicos.itemSetimaFraseAcessoria")}</li>
                    </ul>
                </div>
            </Container_center>
            <Rodape />
        </>
    )
}

export default Servicos;