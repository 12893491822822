import React, { useEffect, useMemo } from "react";
import { Container_center } from "customizados/estilizados"
import { Styled_h1 } from "customizados/estilizados";
import { useTranslation } from "react-i18next";
import Rodape from "components/rodape/index"
import Img from "img/privacidade/politica-privacidade.jpg"
import  Metodos  from "util/helper";

import "./style.scss"
import Cabecalho_padrao from "components/cabecalho-padrao";

const Politica = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);

    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    return (
        <div className="full-content">
              <Cabecalho_padrao imagem={Img} imagemEscura/>
            <Container_center>
                <div className="politica-content">
                    <Styled_h1>{t("politicas.politicas")}</Styled_h1>
                    <p>{t("politicas.textoPrimeiroParagrafoPoliticas")}
                        <a href="https://www.quattror.com.br/" target="_blank">http://portal.quattror.com.br</a>, {t("politicas.textoSegundoParagrafoPoliticas")} <a href="https://portal.quattror.com.br/portal-quattror/" target="_blank"> www.quattor.com.br </a>
                        {t("politicas.textoTerceiroParagrafoPoliticas")}</p>
                    <div className="sessao">
                        <Styled_h1>
                        {t("politicas.tituloSecaoPrimeiraPolitica")}
                        </Styled_h1>
                        <p>{t("politicas.primeiroParagrafoPrimeiraSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoParagrafoPrimeraSecao1Politicas")}</p>
                        <p>{t("politicas.terceiroParagrafoPrimeraSecao1Politicas")}</p>
                    </div>
                    <div className="sessao">
                        <Styled_h1>{t("politicas.tituloSegundaSecaoPolitica")}</Styled_h1>
                        <p>{t("politicas.primeiroParagrafoSegundaSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoParagrafoSegundaSecaoPoliticas")}</p>
                        <p>{t("politicas.terceiroParagrafoSegundaSecaoPoliticas")}</p>
                        <p>{t("politicas.quartoParagrafoSegundaSecaoPoliticas")}</p>
                        <p>{t("politicas.quintoParagrafoSegundaSecaoPoliticas")}</p>
                    </div>
                    <div className="sessao">
                        <Styled_h1>{t("politicas.tituloSecaoTerceiraPolitica")}</Styled_h1>
                        <p>{t("politicas.primeiroParagrafoSecaoPoliticas")}</p>
                    </div>
                    <div className="sessao">
                        <Styled_h1>{t("politicas.tituloSecaoQuartaPolitica")}</Styled_h1>
                        <p>{t("politicas.primeiroParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.terceiroParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.quartoParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.quintoParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.sextoParagrafoQuartaSecaoPoliticas")}</p>
                        <p>{t("politicas.setimoParagrafoQuartaSecaoPoliticas")}</p>
                    </div>
                    <div className="sessao">
                        <Styled_h1>{t("politicas.tituloSecaoQuintaPolitica")}</Styled_h1>
                        <p>{t("politicas.primeiroParagrafoQuintaSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoParagrafoQuintaSecaoPoliticas")}</p>
                        <p>{t("politicas.terceiroParagrafoQuintaSecaoPoliticas")}</p>
                    </div>
                    <div className="sessao">
                    <Styled_h1>{t("politicas.tituloSecaoSextaPolitica")}</Styled_h1>
                        <p>{t("politicas.primeiroParagrafoSextaSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoDoisParagrafoSextaSecaoPoliticas")}</p>
                        <p>{t("politicas.segundoParagrafoSextaSecaoPoliticas")}</p>
                        <p>{t("politicas.terceiroParagrafoSextaSecaoPoliticas")}</p>
                        <p>{t("politicas.quartoParagrafoSextaSecaoPoliticas")}</p>
                    </div>
                    <div className="sessao">
                    <Styled_h1>{t("politicas.tituloSecaoSetimaPolitica")}</Styled_h1>
                        <p>{t("politicas.segundoParagrafoSetimoSecaoPoliticas")}</p>
                        <p>{t("politicas.terceiroParagrafoSetimoSecaoPoliticas")}</p>
                        <p>{t("politicas.quartoParagrafoSetimoSecaoPoliticas")}</p>
                    </div>
                </div>
            </Container_center>
            <Rodape />
        </div>
    )

}

export default Politica;