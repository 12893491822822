import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Esg from '../views/esg/index';
import Incentivos from '../views/incentivos';
import PaginaInicial from '../views/pagina-inicial';
import OndeEstamos from '../views/onde-estamos';
import Projetos from '../views/projetos';
import Sobre from '../views/sobrenos';
import Servicos from '../views/servicos';
import Contatos from '../views/contato';
import Inovacao from '../views/inovacao';
import Politica from '../views/politica/index';
import Cambio from '../views/cambio/index';
import DocumentosPdf from '../views/pdf/documentos/index';

import './style.scss';
export default function Rotas() {
    return (
        <Router>
            <Routes>
                <Route path='*' element={<Navigate to='/' />} />

                <Route path='' exact element={<PaginaInicial />} />

                <Route exact path='esg' element={<Esg />} />

                <Route exact path='onde' element={<OndeEstamos />} />

                <Route exact path='sobre' element={<Sobre />} />

                <Route exact path='servicos' element={<Servicos />} />

                <Route exact path='projetos' element={<Projetos />} />

                <Route exact path='incentivos' element={<Incentivos />} />

                <Route exact path='contatos' element={<Contatos />} />

                <Route exact path='inovacao' element={<Inovacao />} />

                <Route exact path='politica' element={<Politica />} />

                <Route exact path='cambio' element={<Cambio />} />

                <Route exact path='documentos' element={<DocumentosPdf />} />
            </Routes>
        </Router>
    );
}
