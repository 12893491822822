import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container_center } from 'customizados/estilizados';
import { Styled_h1 } from 'customizados/estilizados';
import { useTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256';
import Rodape from 'components/rodape/index';
import Img from 'img/contato/pessoa.jpg';
import Metodos from 'util/helper';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cabecalho_padrao from 'components/cabecalho-padrao';
import { SpinnerCircular } from 'spinners-react';

import 'react-phone-number-input/style.css';

import './style.scss';
import Mapa from 'components/Mapa';

const Contato = () => {
    const { t } = useTranslation(['home']);

    const apiMetodos = useMemo(() => new Metodos(), []);

    const form = useRef();
    const formTc = useRef();

    useEffect(() => {
        apiMetodos.enableDropScroll();
    }, []);

    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [assunto, setAssunto] = useState('');
    const [email, setEmail] = useState('');
    const [texto, setTexto] = useState('');

    const [nomeTc, setNomeTc] = useState('');
    const [telefoneTc, setTelefoneTc] = useState('');
    const [emailTc, setEmailTc] = useState('');
    const [cargoTc, setCargoTc] = useState('');
    const [curriculoTc, setCurriculoTc] = useState('');
    const [spinner, setSpinner] = useState(false);

    const [errorTel, setErrorTel] = useState(null);

    // function validateTelefone(value){
    //     if(value.length === 0){
    //         setErrorTel("Preencha seu telefone de contato.")
    //         return false
    //     } else if (!/^\(\d{2}\) \d{4,5}-\d{4}$/.test(value)){
    //         setErrorTel("Preencha um nomero válido.")
    //         return false
    //     }else {
    //         setErrorTel(null)
    //         return true
    //     }
    // }

    // function validarTelefone(telefone) {
    //     validateTelefone(telefone.value)
    // };

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(null, reader.result);
        };
        reader.onerror = function (error) {
            cb(error, null);
        };
    };

    const onUploadFileChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
            return;
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                setCurriculoTc(result);
            }
        });
    };

    //spinner de loading
    const Spinner = () => {
        if (spinner != false) {
            return (
                <>
                    <SpinnerCircular size={30} />
                </>
            );
        }
    };

    const sendEmail = async (e) => {
        setSpinner(true);

        try {
            e.preventDefault();

            const dados = {
                dsc_nome: nome,
                dsc_telefone: telefone,
                dsc_email: email,
                dsc_assunto: assunto,
                dsc_texto: texto,
                dsc_token: sha256('qu4ttror#secret*' + email)
            };

            const res = await axios.post('https://backendservices.quattror.com.br/api/quattror-email/contato', dados);

            return res.data.sucesso === true ? msgSucesso() : msgFalha();
        } catch (err) {
            msgFalha(err);
        }

        function msgSucesso() {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email enviado com sucesso',
                showConfirmButton: false,
                timer: 1500
            });
            setSpinner(false);
        }

        function msgFalha(erro) {
            apiMetodos.discord(`ERRO: ${erro.message}`, 'Contato', 'CONTATO');
            setSpinner(false);
            Swal.fire({
                icon: 'error',
                title: 'Problemas técnicos',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    const sendEmailTrabalheConosco = async (e) => {
        setSpinner(true);

        try {
            e.preventDefault();

            const tkn = process.env.REACT_APP_QUATTROR + emailTc;

            const hash = sha256(tkn);

            const dados = {
                dsc_nome: nomeTc,
                dsc_email: emailTc,
                dsc_telefone: telefoneTc,
                dsc_setor: cargoTc,
                dsc_anexo: curriculoTc,
                dsc_assunto: '',
                dsc_token: hash
            };

            const res = await axios.post('https://backendservices.quattror.com.br/api/quattror-email/trabalhe-conosco', dados);

            return res.data.sucesso === true ? msgSucesso() : msgFalha();
        } catch (err) {
            msgFalha(err);
        }

        function msgSucesso() {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email enviado com sucesso',
                showConfirmButton: false,
                timer: 1500
            });
            setSpinner(false);
        }
        function msgFalha(erro) {
            apiMetodos.discord(`ERRO: ${erro.message}`, 'Contato', 'TRABALHE CONOSCO');
            setSpinner(false);
            Swal.fire({
                icon: 'error',
                title: 'Problemas técnicos',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    return (
        <div>
            <Cabecalho_padrao imagem={Img} imagemEscura />
            <Container_center>
                <div className='componente-box'>
                    <form className='combo-input' ref={form} onSubmit={sendEmail}>
                        {/* <img className="poligonoContato" src={Poligono} /> */}
                        <div className='contato-combo'>
                            <Styled_h1>{t('contato.titulo')} </Styled_h1>
                            <p>{t('contato.textoContato')}</p>
                        </div>
                        <div className='combo-inputs-data'>
                            <div className='nome'>
                                <input required type='text' autocomplete='off' name='nome' value={nome} maxLength='50' onChange={(e) => setNome(e.target.value)} />
                                <label>{t('contato.inputNome')}</label>
                            </div>
                            <div className='telefone'>
                                <input
                                    required
                                    type='text'
                                    autocomplete='off'
                                    maxLength='11'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    name='telefone'
                                    value={telefone}
                                    onChange={(e) => setTelefone(e.target.value)}
                                />
                                <label>{t('contato.inputTelefone')}</label>
                            </div>
                        </div>
                        <div className='combo-inputs-data'>
                            <div className='assunto'>
                                <input required type='text' autocomplete='off' name='assunto' maxLength='50' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                                <label>{t('contato.inputAssunto')}</label>
                            </div>
                            <div className='email'>
                                <input required type='text' autocomplete='off' maxLength='40' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <label>{t('contato.inputEmail')}</label>
                            </div>
                        </div>
                        <div className='content-textarea'>
                            <textarea cols='30' rows='10' type='text' name='texto' value={texto} onChange={(e) => setTexto(e.target.value)} />
                        </div>
                        <div className='btn-cadastrar'>
                            <button>
                                {!spinner && <>{t('contato.btnEnviar')}</>} {spinner && <>{Spinner()}</>}
                            </button>
                        </div>
                    </form>
                </div>

                {/* <div className='componente-box'>
                    <form className='combo-input' ref={formTc} onSubmit={sendEmailTrabalheConosco}>
                        <div className='contato-combo'>
                            <Styled_h1>{t('contato.tituloTrabalheConosco')} </Styled_h1>
                            <p>{t('contato.textoContato')}</p>
                        </div>
                        <div className='combo-inputs-data'>
                            <div className='nome'>
                                <input required type='text' autocomplete='off' name='nomeTc' maxLength='50' value={nomeTc} onChange={(e) => setNomeTc(e.target.value)} />
                                <label>{t('contato.inputNome')}</label>
                            </div>
                            <div className='telefone'>
                                <input
                                    required
                                    type='tel'
                                    autocomplete='off'
                                    name='telefoneTc'
                                    maxLength='11'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={telefoneTc}
                                    onChange={(e) => setTelefoneTc(e.target.value)}
                                />
                                <label>{t('contato.inputTelefone')}</label>
                            </div>
                        </div>
                        <div className='combo-inputs-data'>
                            <div className='email'>
                                <input required type='text' autocomplete='off' name='emailTc' maxLength='40' value={emailTc} onChange={(e) => setEmailTc(e.target.value)} />
                                <label>{t('contato.inputEmail')}</label>
                            </div>
                            <div className='cargo-content'>
                                <input required type='text' autocomplete='off' name='cargoTc' maxLength='40' value={cargoTc} onChange={(e) => setCargoTc(e.target.value)} />
                                <label>{t('contato.inputCargo')}</label>
                            </div>
                        </div>
                        <div className='content-file'>
                            <div>{t('contato.curruculo')}:</div>
                            <input required c lassName='botaoEnviarArquivo' type='file' name='filetobase64' onChange={onUploadFileChange} accept='application/pdf' />
                        </div>
                        <div className='btn-cadastrar'>
                            <button>
                                {!spinner && <>{t('contato.btnEnviar')}</>} {spinner && <>{Spinner()}</>}
                            </button>
                        </div>
                    </form>
                </div> */}
            </Container_center>
            <div className='mapa-contato'>
                <Mapa />
            </div>
            <Rodape />
        </div>
    );
};

export default Contato;
