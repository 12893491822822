import React, { useEffect, useMemo, useState } from "react";
import { Styled_h1 } from "customizados/estilizados"
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useRef } from "react";
import Cabecalho_padrao from "components/cabecalho-padrao";
import Rodape from "components/rodape/index"
import Img from "img/cambio-escura.png"

import "./style.scss"

const Cambio_economia = () => {

    const { t } = useTranslation(["home"])

    return (
        <div>
            <Cabecalho_padrao imagem={Img} imagemEscura/>
            <div>
            <Styled_h1 className="cambio-titulo" style={{ marginTop: "20px", display: 'flex', justifyContent: 'center' }} >
                    {t("cambio.cambio")}
                </Styled_h1 >
                <iframe 
                    class="loading-iframe"
                    style={{marginTop: "40px", width: "100%", height: "1500px" }}
                    src="https://portal.quattror.com.br/portal-quattror/apps/cotacao/app.php?background=ffffff&amp;color=000000" 
                    width="490px;" height="320px;" scrolling="no" frameborder="0">
                </iframe> 
            </div>
            <Rodape />
        </div>
    )
}

export default Cambio_economia;