import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from 'img/logo.png';
import i18next from 'i18next';
import './style.scss';
import axios from 'axios';

const Cabecalho_padrao = (props) => {
    const corte = props.corte ?? true;

    const { i18n, t } = useTranslation(['home']);

    const [open, setOpen] = useState(false);

    const [login, setLogin] = useState(false);

    const openNavResponsivo = () => {
        //Alteração para tela de naveação responsiva
        var menu = document.getElementById('togler-open-padrao');
        document.getElementById('togler-open-padrao').addEventListener('click', () => {
            document.getElementById('MenuMobile').style.display = 'block';
            menu.classList.add('botaoMenuMobileAtivo');
        });
    };
    //Troca de cor do menu ao dar scroll
    const trocarCorMenu = () => {
        var menu = document.getElementById('MenuSuperior');
        window.addEventListener('scroll', function () {
            if (this.window.scrollY > 50) {
                menu.classList.add('menuColor');
            } else {
                menu.classList.remove('menuColor');
            }
        });
    };
    //Menu escondido ao dar scroll
    const esconderMenu = () => {
        var menuScroll = window.pageYOffset;
        var menu = document.getElementById('MenuSuperior');
        window.addEventListener('scroll', function () {
            var menuScrollPositivo = window.pageYOffset;
            if (this.window.scrollY > 800) {
                if (menuScroll > menuScrollPositivo) {
                    menu.style.top = '0';
                } else {
                    menu.style.top = '-70px';
                }
                menuScroll = menuScrollPositivo;
            }
        });
    };

    const closenavResponsivo = () => {
        //Alteração para tela de navegação responsiva
        var menu = document.getElementById('togler-open-padrao');
        document.getElementById('togler-close-padrao').addEventListener('click', () => {
            document.getElementById('MenuMobile').style.display = 'none';
            document.getElementById('togler-open-padrao').style.display = 'block';
            menu.classList.remove('botaoMenuMobileAtivo');
        });
    };

    useEffect(() => {
        esconderMenu();
        trocarCorMenu();
        openNavResponsivo();
        closenavResponsivo();
        if (localStorage.getItem('i18nextLng')?.length > 2) {
            i18next.changeLanguage('pt');
        }
    }, []);

    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');

    //Botao login Menu mobile
    const barraMenuMobile = () => {
        var menuMobile = document.getElementById('menuMobile');
        var trocarCor = document.getElementById('TrocarBG');
        var trocarSeta = document.getElementById('TrocarSeta');

        if (menuMobile.style.display === 'block') {
            menuMobile.style.display = 'none';
            trocarCor.classList.remove('LingaugemTrocarCor');
            trocarSeta.classList.remove('trocarSeta');
        } else {
            menuMobile.style.display = 'block';
            trocarCor.classList.add('LingaugemTrocarCor');
            trocarSeta.classList.add('trocarSeta');
        }
    };

    //Seletor Linguagem
    const [linguaSelecionada, setLinguaSelecionada] = React.useState('PT');
    const selecionarLinguagem = (lingua) => {
        i18n.changeLanguage(lingua);
        if (lingua === 'pt') {
            setLinguaSelecionada('PT');
        }
        if (lingua === 'en') {
            setLinguaSelecionada('EN');
        }
        if (lingua === 'es') {
            setLinguaSelecionada('ES');
        }
    };
    //Seletor Linguagem Mobile
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    const enviaFormulario = async (e) => {
        e.preventDefault();
        var nome = document.getElementById('usuario').value;
        var senha = document.getElementById('senhasite').value;
        if (nome === '' || senha === '') {
            return;
        }
        var usuarioEncoded = btoa(nome);
        var senhasiteEncoded = btoa(senha);
        document.getElementsByName('usuario_encoded')[0].value = usuarioEncoded;
        document.getElementsByName('senhasite_encoded')[0].value = senhasiteEncoded;
        var form = document.getElementById('loginForm');

        form.method = 'POST';
        form.action = process.env.REACT_APP_URL_PORTAL;
        form.submit();

        document.getElementById('usuario').value = null;
        document.getElementById('senhasite').value = null;
    };

    return (
        <div
            className={`header-content-padrao `}
            style={{
                background: `${props.imagemEscura ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${props.imagem}) no-repeat` : `url(${props.imagem}) no-repeat`} `,
                backgroundSize: 'cover',

                backgroundPositionY: `${props.positionY}`
            }}>
            <div className='MenuSuperior' id='MenuSuperior'>
                <div className='logo-ul-padrao'>
                    <Link to='/'>
                        <img src={Logo} />
                    </Link>
                    <div className='GuiaSuperior'>
                        <div className='guiaMenu'>
                            <li className='itemMenu'>
                                <Link to='/sobre' style={{ fontWeight: `${props.fontW}` }}>
                                    {t('cabecalhoMain.sobrenos')}
                                </Link>
                            </li>
                            <li className='itemMenu'>
                                <Link to='/servicos' style={{ fontWeight: `${props.fontW}` }}>
                                    {t('cabecalhoMain.servicos')}
                                </Link>
                            </li>
                            <li className='itemMenu'>
                                <Link to='/onde' style={{ fontWeight: `${props.fontW}` }}>
                                    {t('cabecalhoMain.ondeEstamos')}
                                </Link>
                            </li>
                            <li className='itemMenu'>
                                <Link to='/contatos' style={{ fontWeight: `${props.fontW}` }}>
                                    {t('cabecalhoMain.contato')}
                                </Link>
                            </li>
                            <li className='itemMenu'>
                                <Link to='/esg' style={{ fontWeight: `${props.fontW}` }}>
                                    {t('cabecalhoMain.social')}
                                </Link>
                            </li>
                        </div>

                        <div className='guiaBotao'>
                            <button className='BotaoLogin' onClick={() => setLogin(!login)}>
                                {t('cabecalhoMain.btnLogin')}
                            </button>
                        </div>
                        {login && (
                            <div className='balao-padrao'>
                                <form onSubmit={(e) => enviaFormulario(e)} id='loginForm'>
                                    <input type='text' name='usuario' id='usuario' placeholder={t('cabecalhoMain.placeholderUsuario')} />
                                    <input type='password' name='senhasite' id='senhasite' placeholder={t('cabecalhoMain.placeholderSenha')} />
                                    <input type='hidden' name='usuario_encoded' id='usuario' placeholder={t('cabecalhoMain.placeholderUsuario')} />
                                    <input type='hidden' name='senhasite_encoded' id='senhasite' placeholder={t('cabecalhoMain.placeholderSenha')} />
                                    <input type='hidden' id='origem' name='origem' value='site' />
                                    <input type='hidden' name='e' value='L' />
                                    <a href=''>{t('cabecalhoMain.esqueceuSenha')}</a>
                                    <a href=''>{t('cabecalhoMain.primeiroAcesso')}</a>
                                    <button id='btn' className='btn' type='submit'>
                                        {t('cabecalhoMain.btnEntrar')}
                                    </button>
                                    {/* <a href={`https://portal.quattror.com.br/portal-quattror/?e=L&usuario=${usuario}&senhasite=${senha}`} id="btn" className="btn" target="_blank">{t("cabecalhoMain.btnEntrar")}</a> */}
                                </form>
                            </div>
                        )}
                        <div className='guiaLinguas'>
                            <div className='Linguagem'>
                                <div className='Selecionado'>
                                    <div className='Lingua'>{linguaSelecionada}</div>
                                    <div className='SetaLingua'>
                                        <div className='setaLinguagem'>
                                            <i className='fa-solid fa-chevron-up'></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='opcoesLinguagem'>
                                    <div className='LinguagemOpcoes' onClick={() => selecionarLinguagem('pt')} style={{ marginBottom: '10px' }}>
                                        Português
                                    </div>
                                    <div className='LinguagemOpcoes' onClick={() => selecionarLinguagem('en')} style={{ marginBottom: '10px' }}>
                                        Inglês
                                    </div>
                                    <div className='LinguagemOpcoes' onClick={() => selecionarLinguagem('es')}>
                                        Espanhol
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='guiaMenuMobile'>
                        <div className='botaoMenuMobile' id='togler-open-padrao'>
                            <span className='menuMobi'>
                                <i
                                    className='fa-regular fa-bars'
                                    style={{
                                        color: `${props.backgroundToggle}`
                                    }}></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='Mobile' id='MenuMobile'>
                <div className='botaoFechar' id='togler-close-padrao'>
                    <span className='fecharBotao'>
                        <i className='fa-solid fa-x'></i>
                    </span>
                </div>
                <div className='MobileContainer'>
                    <div className='itemMobile'>
                        <h3 className='itemMenuMobile'>
                            <Link to='/sobre' style={{ fontWeight: `${props.fontW}` }}>
                                {t('cabecalhoMain.sobrenos')}
                            </Link>
                        </h3>
                    </div>
                    <div className='itemMobile'>
                        <h3 className='itemMenuMobile'>
                            <Link to='/servicos' style={{ fontWeight: `${props.fontW}` }}>
                                {t('cabecalhoMain.servicos')}
                            </Link>
                        </h3>
                    </div>
                    <div className='itemMobile'>
                        <h3 className='itemMenuMobile'>
                            <Link to='/onde' style={{ fontWeight: `${props.fontW}` }}>
                                {t('cabecalhoMain.ondeEstamos')}
                            </Link>
                        </h3>
                    </div>
                    <div className='itemMobile'>
                        <h3 className='itemMenuMobile'>
                            <Link to='/contatos' style={{ fontWeight: `${props.fontW}` }}>
                                {t('cabecalhoMain.contato')}
                            </Link>
                        </h3>
                    </div>
                    <div className='itemMobile'>
                        <h3 className='itemMenuMobile'>
                            <Link to='/esg' style={{ fontWeight: `${props.fontW}` }}>
                                {t('cabecalhoMain.social')}
                            </Link>
                        </h3>
                    </div>
                    <div className='itemMobileLogin' id='TrocarBG' onClick={() => barraMenuMobile()}>
                        <h3 className='itemMenuMobile'>{t('cabecalhoMain.acessarAba')}</h3>
                        <div className='guiaSeta' id='TrocarSeta'>
                            <i className='fa-solid fa-chevron-up'></i>{' '}
                        </div>
                    </div>
                    <div id='menuMobile' className='LoginGuia'>
                        <div className='GuiaLogin'>
                            <form
                                action={process.env.REACT_APP_URL_PORTAL}
                                // action='http://10.5.10.92/portal-quattror/index.php'
                                id='loginForm'
                                method='POST'>
                                <div className='guiaInput'>
                                    <input
                                        type='text'
                                        name='usuario'
                                        placeholder={t('cabecalhoMain.placeholderUsuario')}
                                        // onChange={(e) =>
                                        // 	setUsuario(e.target.value)
                                        // }
                                    />
                                    <input
                                        type='password'
                                        name='senhasite'
                                        placeholder={t('cabecalhoMain.placeholderSenha')}
                                        // onChange={(e) =>
                                        // 	setSenha(e.target.value)
                                        // }
                                    />
                                </div>
                                <div className='guiaAjuda'>
                                    <a href=''>{t('cabecalhoMain.esqueceuSenha')}</a>
                                    <a href=''>{t('cabecalhoMain.primeiroAcesso')}</a>
                                </div>
                                <input type='hidden' name='usuario_encoded' id='usuario' placeholder={t('cabecalhoMain.placeholderUsuario')} />
                                <input type='hidden' name='senhasite_encoded' id='senhasite' placeholder={t('cabecalhoMain.placeholderSenha')} />
                                <input type='hidden' id='origem' name='origem' value='site' />
                                <input type='hidden' name='e' value='L' />
                                <button
                                    id='btn'
                                    className='btn'
                                    // type='submit'
                                    onClick={() => enviaFormulario()}>
                                    {t('cabecalhoMain.btnEntrar')}
                                </button>
                                {/* <a
								href={`https://portal.quattror.com.br/portal-quattror/?e=L&usuario=${usuario}&senhasite=${senha}`}
								id='btn'
								className='btn'
								target='_blank'>
								{t('cabecalhoMain.btnEntrar')}
							</a> */}
                            </form>
                        </div>
                    </div>
                    <div className='itemMobileDuplo'>
                        <div className='LinguagemTexto'>
                            <h3 className='itemMenuMobile'>{t('cabecalhoMain.lingaugemDiv')}</h3>
                        </div>
                        <div className='linguagemMobile'>
                            <select
                                className='selectLinguagem'
                                style={{
                                    color: `${props.colorLinguas}`,
                                    background: `${props.color}`
                                }}
                                value={localStorage.getItem('i18nextLng')}
                                onChange={handleLanguageChange}>
                                <option value='pt'>Português</option>
                                <option value='en'>Inglês</option>
                                <option value='es'>Espanhol</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {corte && <div className='borda-padrao' onClick={() => setOpen(!open)}></div>}
        </div>
    );
};

export default Cabecalho_padrao;
